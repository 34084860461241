import "./import/modules.js";
import "slick-carousel";
import { TweenMax } from "gsap";

$(document).ready(function () {
  var imgLoad = imagesLoaded(".content");

  var progressBar = $(".c-preloader__progress"),
    count = $(".c-preloader__count"),
    images = $("img").length,
    loadedCount = 0,
    loadingProgress = 0,
    tlProgress = new TimelineMax();

  imgLoad.on("progress", function (instance, image) {
    loadProgress();
  });

  function loadProgress(imgLoad, image) {
    loadedCount++;

    loadingProgress = loadedCount / images;
    console.log(loadingProgress);

    TweenMax.to(tlProgress, 1, { progress: loadingProgress });
  }

  var tlProgress = new TimelineMax({
    onUpdate: countPercent,
    onComplete: loadComplete,
  });

  tlProgress.to(progressBar, 1, { width: "100%" });

  function countPercent() {
    var newPercent = (tlProgress.progress() * 100).toFixed();
    count.text(newPercent + "%");
  }

  function loadComplete() {
    var tlEnd = new TimelineMax();
    tlEnd
      .to(count, 0.5, { autoAlpha: 0 })
      .to(".c-preloader", 0.5, { scaleX: 0, transformOrigin: "center right" });
  }
  Splitting();

  ScrollOut({
    threshold: 0.1,
    once: true,
  });

  var tabLinks = document.querySelectorAll(".tabs__link");
  var tabContents = document.querySelectorAll(".tabs__content");
  tabLinks[0].classList.add("active");
  tabContents[0].classList.add("active");
  tabLinks.forEach(function (tabLink, i) {
    tabLink.addEventListener("click", function () {
      tabLinks.forEach(function (tabLink) {
        return tabLink.classList.remove("active");
      });
      tabContents.forEach(function (tabContent) {
        return tabContent.classList.remove("active");
      });
      tabLink.classList.add("active");
      tabContents[i].classList.add("active");
    });
  });

  $(".slider-photo").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    rows: 0,
    infinity: true,
    asNavFor: ".slider-nav-photo",
    swipe: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  });
  $(".slider-nav-photo").slick({
    slidesToShow: 11,
    slidesToScroll: 1,
    asNavFor: ".slider-photo",
    dots: false,
    arrows: false,
    rows: 0,
    infinity: true,
    centerMode: true,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  });

  $(".slider-client").slick({
    infinite: true,
    arrows: true,
    dots: false,
    autoplay: false,
    rows: 0,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          infinite: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          variableWidth: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          infinite: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          variableWidth: true,
        },
      },
    ],
  });
  $(".slider-victor").slick({
    arrows: true,
    dots: false,
    infinite: true,
    speed: 2000,
    fade: false,
    rows: 0,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          variableWidth: true,
          centerMode: true,
        },
      },
    ],
  });

  $(".accordion__title").click(function (e) {
    e.preventDefault();
    $(".slider-victor").slick("refresh");
  });

  $("#toggle").click(function () {
    $(this).toggleClass("active");
    $("#overlay").toggleClass("open");
    $("html, body").toggleClass("body-hidden");
    $(".overlay-menu a").click(function () {
      $("#toggle").removeClass("active");
      $("#overlay").removeClass("open");
      $("html, body").removeClass("body-hidden");
    });
  });
});

var scroll = new SmoothScroll('a[href^="#"]', {
  speed: 300,
});

$(function () {
  $(".tabs__link, .active").click(function () {
    $("iframe").each(function () {
      var src = $(this).attr("src");
      $(this).attr("src", src);
    });
  });
});

$(function () {
  //BEGIN
  $(".accordion__title").on("click", function (e) {
    e.preventDefault();
    var $this = $(this);

    if (!$this.hasClass("accordion-active")) {
      $(".accordion__content").slideUp("active-content");
      $(".accordion__title").removeClass("accordion-active");
      $(".accordion__arrow").removeClass("accordion__rotate");
    }

    $this.toggleClass("accordion-active");
    $this.next().slideToggle();
    $(".accordion__arrow", this).toggleClass("accordion__rotate");
  });
  //END
});

$(function () {
  $(".accordion__title, .accordion-active").click(function () {
    $("iframe").each(function () {
      var src = $(this).attr("src");
      $(this).attr("src", src);
    });
  });
});

$(window).on("load scroll", function () {
  var scrolled = $(this).scrollTop();
  $("#title").css({
    transform: "translate3d(0, " + -(scrolled * 0.2) + "px, 0)", // parallax (20% scroll rate)
    opacity: 1 - scrolled / 400, // fade out at 400px from top
  });
  $(".header-bg").css(
    "transform",
    "translate3d(0, " + -(scrolled * 0.25) + "px, 0)"
  ); // parallax (25% scroll rate)
});

$(".video-name-btn").click(function () {
  $(".video-name-btn").removeClass("active-video");
  $(this).addClass("active-video");
});

jQuery("#introVid1").click(function () {
  $("#videoContainer").show();
  autoPlayVideo("147842467");
});

jQuery("#introVid2").click(function () {
  $("#videoContainer").show();
  autoPlayVideo("148470865");
});

jQuery("#introVid3").click(function () {
  $("#videoContainer").show();
  autoPlayVideo("148111448");
});

jQuery("#introVid4").click(function () {
  $("#videoContainer").show();
  autoPlayVideo("147842467");
});

function autoPlayVideo(vcode) {
  "use strict";
  $("#videoContainer").html(
    '<iframe src="https://player.vimeo.com/video/' +
      vcode +
      '?autoplay=0&loop=0&autopause=1" frameborder="0" allowfullscreen wmode="Opaque"></iframe>'
  );
}

$(".play-audio").click(function (e) {
  e.preventDefault();
  $(".play-audio").not(this).removeClass("active-audio");
  $(this).toggleClass("active-audio");
});

jQuery(document).ready(function () {
  var youtube_src = jQuery("#videoContainer iframe").attr("src");
  jQuery("#videoContainer").on(".tabs__link.active", function (e) {
    jQuery("#videoContainer iframe").attr("src", null);
  });
});

$("#tabs__contents").on(".tabs__contents.active", function (e) {
  var leg = $("#my-video").attr("src");
  $("#my-video").attr("src", leg);
});

// Отправка заявки
$(document).ready(function () {
  $("#form").submit(function () {
    // проверка на пустоту заполненных полей. Атрибут html5 — required не подходит (не поддерживается Safari)
    if (document.form.name.value == "" || document.form.phone.value == "") {
      valid = false;
      return valid;
    }
    $.ajax({
      type: "POST",
      url: "../mail.php",
      data: $(this).serialize(),
    }).done(function () {
      $(".js-overlay-thank-you").fadeIn();
      $(this).find("input").val("");
      $("#form").trigger("reset");
    });
    return false;
  });
  // Закрыть попап «спасибо»
  $(".js-close-thank-you").click(function () {
    // по клику на крестик
    $(".js-overlay-thank-you").fadeOut();
  });

  $(document).mouseup(function (e) {
    // по клику вне попапа
    var popup = $(".popup");
    if (e.target != popup[0] && popup.has(e.target).length === 0) {
      $(".js-overlay-thank-you").fadeOut();
    }
  });
});

document.getElementById("phone").addEventListener("input", function (e) {
  var x = e.target.value
    .replace(/\D/g, "")
    .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  e.target.value = !x[2]
    ? x[1]
    : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
});

function customPopup() {
  let $btnShowPopup = $(".js-open-popup");
  let $btnClosePopup = $(".js-close-popup");
  let $popup = $(".js-custom-popup");

  $btnShowPopup.on("click", function () {
    let targetPopup = $(this).attr("data-target");
    $("[data-popup=" + targetPopup + "]").addClass("is-active");
  });

  $btnClosePopup.on("click", function () {
    $(this).parents(".is-active").removeClass("is-active");
  });

  $popup.on("click", function (event) {
    if (
      !$(event.target).closest(".js-custom-popup-holder").length &&
      !$(event.target).is("js-custom-popup")
    ) {
      if ($popup.hasClass("is-active")) {
        $popup.removeClass("is-active");
      }
    }
  });
}
customPopup();

// document.addEventListener(".play", function (e) {
//   var audios = document.getElementsByTagName("audio");
//   for (var i = 0, len = audios.length; i < len; i++) {
//     if (audios[i] != e.target) {
//       audios[i].pause();
//     }
//   }
// });

var previons = 100;
var alles_audio = document.getElementsByTagName("audio");
for (i = 0; i < alles_audio.length; i++) {
  alles_audio[i].id = i;
  alles_audio[i].onplay = function () {
    if (previons != 100) {
      alles_audio[previons].pause();
    }
    this.play();
    previons = this.id;
  };
}
